<template>
  <div class="base-view">
    <TrackingScripts v-if="includeTracking" />
    <div class="content-view">
      <AppHeader class="header" />
      <div class="overflow-container">
        <router-view class="router" />
        <AppFooter class="footer" />
      </div>
    </div>
  </div>
</template>

<script>
import { INCLUDE_TRACKING } from '@/utilities/constants';
import TrackingScripts from '@/components/common/tracking/TrackingScripts';
import AppHeader from '@/components/header/AppHeader';
import AppFooter from '@/components/footer/AppFooter';

export default {
  name: 'AuthenticatedTemplate',
  components: { AppFooter, AppHeader, TrackingScripts },
  computed: {
    includeTracking: () => INCLUDE_TRACKING,
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/variables';

.base-view {
  height: 100%;

  display: flex;
  flex-direction: row;
}

.side-menu {
  flex-shrink: 0;
}

.content-view {
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  .overflow-container {
    overflow: auto;

    height: 100%;

    display: flex;
    flex-direction: column;
  }

  .header,
  .footer {
    flex-shrink: 0;
    padding: 0 $main-box-padding;
  }

  .router {
    flex: 1;
  }
}
</style>

export function stringIsNullOrWhitespace(input) {
  return !input || !input.trim();
}

export function stringIsDomain(input) {
  const re =
    /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/;
  return re.test(input);
}

export const NEWLINE_REGEX = /\r\n|\n\r|\n|\r/g;

<template>
  <div class="container">
    <SplashPage
      v-if="!showSplashpageForUser && splashPageOpen"
      :open="splashPageOpen"
      @close="closeSplashPage"
    />
    <div class="home">
      <div class="home">
        <HomeWelcome />
        <HomeProducts class="products" />
      </div>
    </div>
  </div>
</template>

<script>
import HomeWelcome from '@/components/home/HomeWelcome';
import HomeProducts from '@/components/home/HomeProducts';
import SplashPage from '@/components/home/SplashPage';
import { getLocalStorage } from '@/utilities/storage-helper';

export default {
  name: 'Home',
  components: { HomeProducts, HomeWelcome, SplashPage },
  data() {
    return {
      splashPageOpen: false,
      showSplashpageForUser: false,
    };
  },
  methods: {
    closeSplashPage() {
      this.splashPageOpen = false;
    },
    loadCheckbox() {
      this.showSplashpageForUser = getLocalStorage('splashpage');
    },
  },
  mounted() {
    //as requested on 6-04-2023, disabled the splashpage.
    //data: splashPageOpen = false
    // this.loadCheckbox();
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/variables';
@import '~@/styles/mixins';

.container {
  background: $background-color url('../assets/home/home-bg.png') no-repeat
    fixed center center;
  @include background-size-cover;
  padding: 3rem;

  display: flex;
  align-items: center;
}

.home {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1200px;
  margin: auto;

  .welcome {
    flex-basis: 33%;
    margin-bottom: 1rem;
  }

  .products {
    flex-basis: 67%;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }
}
</style>

<template>
  <div>
    <input type="hidden" id="hidEnterprise" :value="enterpriseName" />
    <input type="hidden" id="hidSub" :value="userId" />
  </div>
</template>

<script>
/* eslint-disable */
import { GTM_ID } from '@/utilities/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'GoogleTagManager',
  computed: {
    ...mapGetters({
      userId: 'auth/userId',
      enterpriseName: 'auth/enterpriseName',
    }),
  },
  created() {
    // Google Tag Manager
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer',GTM_ID);
  },
};
</script>

<style scoped>
div {
  display: none;
}
</style>

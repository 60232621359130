import apiClient from './sso-api-client';

// this is a base class to derive from for API requests
// the apiClient is configured with auth headers, automatic refreshing of tokens
// and automatic logout on failing requests
export default class SsoApiService {
  get(url) {
    return apiClient.get(url);
  }

  post(url, data) {
    return apiClient.post(url, data);
  }

  put(url, data) {
    return apiClient.put(url, data);
  }

  delete(url, data = null) {
    const options = {};
    if (data != null) options.data = data;
    return apiClient.delete(url, options);
  }
}

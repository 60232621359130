<template>
  <a :href="destination" :target="enabled ? null : '_blank'">
    <div class="product" :class="enabled ? 'active' : 'inactive'">
      <slot name="content"></slot>
      <div class="invalid_product" v-if="!enabled">
        <p>
          <strong>No active subscription</strong>
          click for more info
        </p>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: 'HomeProduct',
  props: {
    destination: {
      type: [String, URL],
      required: true,
      default: '#',
    },
    enabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/variables';
@import '~@/styles/mixins';

a {
  text-decoration: none;
}

.product {
  overflow: hidden;
  margin-bottom: 1rem;

  &:hover {
    @include hover-box-shadow;
    @include transition;
  }

  .invalid_product {
    display: none;
  }

  &.inactive {
    position: relative;
    opacity: 0.5;

    .invalid_product {
      display: block;
      position: absolute;
      right: calc(-100px - 15px);
      width: 100px;
      background: $orange;
      top: 0px;
      height: 100%;
      border-radius: 0px $border-radius $border-radius 0px;
      color: white;
      padding-left: 15px;
      font-size: 10px;
      line-height: 12px;

      @include transition;

      strong {
        font-size: 11px;
        display: block;
        margin-bottom: 10px;
      }
    }

    &:hover {
      box-shadow: none;
      opacity: 1;
      color: initial;

      .invalid_product {
        transform: translateX(-100px - 15px);
        @include transition;
      }
    }
  }
}

.coming-soon {
  strong {
    font-size: 1rem !important;
    margin-top: 2rem;
  }
}
</style>

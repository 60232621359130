<template>
  <div class="user-info">
    <div class="name">{{ name }}</div>
    <div v-if="enterprise"><span class="sep">|</span>{{ enterprise }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import routeInfo from '@/router/route-info';

export default {
  name: 'HeaderUserInfo',
  computed: {
    ...mapGetters({
      name: 'auth/userFullName',
      enterprise: 'auth/enterpriseName',
    }),
    routeInfo: () => routeInfo,
  },
};
</script>

<style scoped lang="scss">
.user-info {
  display: flex;
  flex-direction: row;

  .sep {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
</style>

<template>
  <footer>
    <AcrossLogoPVH />
  </footer>
</template>

<script>
import AcrossLogoPVH from '@/components/common/AcrossLogoPVH';

export default {
  name: 'AppFooter',
  components: { AcrossLogoPVH },
};
</script>

<style scoped lang="scss">
@import '~@/styles/variables';

footer {
  background-color: $white;
  height: $footer-height;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding-left: $box-padding;
  padding-right: $box-padding;

  width: 100%;
  box-sizing: border-box;
}
</style>

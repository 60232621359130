import axios from 'axios';
import store from '@/store';
import { SSO_BASE_URL } from '@/utilities/constants';

// this basic axios instance is configured with a base url
// and set up to add authentication headers automatically,

const apiClient = axios.create();

apiClient.defaults.baseURL = `${SSO_BASE_URL}/api`;

apiClient.interceptors.request.use((config) => {
  const accessToken = getAccessToken();
  if (accessToken != null) {
    config.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }

  return config;
});

export default apiClient;

function getAccessToken() {
  return store.getters['auth/getAccessToken'];
}

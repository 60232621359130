<template>
  <div></div>
</template>

<script>
/* eslint-disable */
import { INTERCOM_APP_ID } from '@/utilities/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'Intercom',
  computed: {
    ...mapGetters({
      userFullName: 'auth/userFullName',
      userId: 'auth/userId',
      userEmail: 'auth/userEmail',
      enterpriseName: 'auth/enterpriseName',
      intercomUserHash: 'auth/intercomUserHash',
    }),
  },
  watch: {
    $route() {
      window.Intercom('update');
    },
  },
  mounted() {
    window.intercomSettings = {
      app_id: INTERCOM_APP_ID,
      name: this.userFullName,
      email: this.userEmail,
      user_id: this.userId,
      user_hash: this.intercomUserHash,
      AccountName: this.enterpriseName,
    };
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + INTERCOM_APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  },
};
</script>

<style scoped>
div {
  display: none;
}
</style>
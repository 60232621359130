<template>
  <div class="relative">
    <div class="carousel-view">
      <p>
        More than 6,000 of your peers use Navigator365&trade; to answer
        questions like:
      </p>
      <carousel
        ref="carousel"
        :perPage="1"
        paginationColor="#afb7c3"
        paginationActiveColor="#12aeef"
        :paginationSize="6"
        :loop="true"
        paginationPosition="bottom"
        :autoplay="true"
        :autoplayTimeout="5000"
        v-if="!isLoading"
      >
        <slide v-for="(slide, index) in slides" class="slide" :key="index">
          <img :src="slide.img" />
        </slide>
      </carousel>
      <div class="carousel-controls">
        <a @click.prevent="prevSlide" class="nav-btn">
          <inline-svg
            class="img"
            :src="require('@/assets/home/splashpage/carousel/arrow-left.svg')"
        /></a>
        <a @click.prevent="nextSlide" class="nav-btn"
          ><inline-svg
            class="img"
            :src="require('@/assets/home/splashpage/carousel/arrow-right.svg')"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: 'Carouseltest',
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      current: 1,
      nextLabel: '>',
      prevLabel: '<',
      isLoading: true,
      slides: [
        {
          img: require('@/assets/home/splashpage/carousel/0001_ideal_channel_mix_B.png'),
        },
        {
          img: require('@/assets/home/splashpage/carousel/0002_maximise-impact.png'),
        },
        {
          img: require('@/assets/home/splashpage/carousel/0003_3rd-party-media.png'),
        },
        {
          img: require('@/assets/home/splashpage/carousel/0004_company-benchmark.png'),
        },
        {
          img: require('@/assets/home/splashpage/carousel/0005_Covid.png'),
        },
        {
          img: require('@/assets/home/splashpage/carousel/0006_Channels-on-the-rize.png'),
        },
      ],
    };
  },
  methods: {
    nextSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage());
    },
    prevSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage());
    },
    next() {
      this.current++;
      if (this.current === 6) {
        this.current = 1;
      }
      const element = this.slides.splice(this.current, 1)[0];
      this.slides.splice(2, 0, element);
    },
    previous() {
      const last = this.slides.pop();
      this.slides = [last].concat(this.slides);
    },
  },
  created() {
    const images = this.slides.map((imageSrc) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imageSrc.img;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    Promise.all(images)
      .then(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        console.error('Some image(s) failed loading!');
        console.error(error.message);
      });
  },
};
</script>
<style scoped lang="scss">
@import '~@/styles/variables';

.relative {
  position: relative;
  width: 100%;
  height: 100%;
}
.carousel-view {
  display: flex;
  flex-direction: column;
  background-color: $gray-light-splash;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  position: relative;
  .VueCarousel {
    width: 80%;
    .VueCarousel-wrapper {
      margin-bottom: 1rem;
    }
    img {
      width: 100%;
    }
  }

  .carousel-controls {
    padding: 0 0 0 2rem;
    display: flex;
    width: 68%;
    justify-content: flex-end;
    .nav-btn {
      background-color: $blue-light;
      border-radius: 50%;
      width: 1.75rem;
      height: 1.75rem;
      display: block;
      margin-left: 0.5rem;
      &:hover {
        background-color: $blue-darker;
        cursor: pointer;
      }
      .img {
        width: 0.8rem;
        margin: auto;
        display: block;
        margin-top: 0.55rem;
        fill: white !important;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  p {
    width: 74%;
    font-weight: 600;
    color: $text-color;
    margin: 0;
    font-size: 1.1rem;
    color: #646d7e;
    margin-bottom: 1.5rem;
  }
}
</style>
<style lang="scss">
@import '~@/styles/variables';
.VueCarousel-pagination {
  position: relative;
  .VueCarousel-dot-container {
    left: 0.75rem;
    position: absolute;
    top: 0.5rem;
    margin-top: -1.5rem !important;
  }
}
.VueCarousel-dot {
  padding: 6px !important;
  &:hover {
    background-color: $blue-darker !important;
    cursor: pointer;
  }
}
.VueCarousel-dot--active {
  border: 1px solid $blue-light !important;
  padding: 5px !important;
  &:hover {
    background-color: $blue-light !important;
    cursor: pointer;
  }
}
</style>

<template>
  <div>
    <button
      type="button"
      class="btn-dark btn-invite"
      @click="show = true"
      v-if="canInvite"
    >
      <i class="fas fa-user-plus"></i>
      Invite a colleague
    </button>

    <InviteColleague v-if="show" @close="show = false" />
  </div>
</template>

<script>
import InviteColleague from '@/components/common/modals/InviteColleague';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'HeaderInviteColleague',
  components: { InviteColleague },
  computed: {
    ...mapGetters({
      canInvite: 'auth/canInvite',
    }),
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    ...mapActions({
      fetchBusinesses: 'auth/fetchBusinesses',
    }),
  },
  mounted() {
    this.fetchBusinesses();
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/variables';

.btn-invite {
  margin-right: 0.5rem;
  background-color: $green;

  &:hover {
    background-color: $green-dark;
  }
}
</style>

import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth';
import invitations from './modules/invitations';
import scala from './modules/scala';
import subscriptions from './modules/subscriptions';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    invitations,
    scala,
    subscriptions,
  },
});

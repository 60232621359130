<template>
  <div id="app">
    <EnvironmentIndicator class="env-indicator" />
    <AuthenticatedTemplate v-if="isAuthenticated"></AuthenticatedTemplate>
    <UnauthenticatedTemplate v-else></UnauthenticatedTemplate>
  </div>
</template>

<script>
import UnauthenticatedTemplate from '@/views/templates/UnauthenticatedTemplate';
import AuthenticatedTemplate from '@/views/templates/AuthenticatedTemplate';
import { mapGetters } from 'vuex';
import EnvironmentIndicator from '@/components/common/EnvironmentIndicator';

export default {
  components: {
    EnvironmentIndicator,
    UnauthenticatedTemplate,
    AuthenticatedTemplate,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
    }),
  },
};
</script>

<style lang="scss">
#app {
  height: 100%;
  display: flex;
  flex-direction: column;

  > * {
    overflow: auto;
    flex-grow: 1;
  }

  .env-indicator {
    flex-grow: 0;
  }
}
</style>

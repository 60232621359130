<template>
  <img
    :src="require('../../assets/logo/nav365.svg')"
    alt="Navigator365"
    :class="{ inline: inline }"
  />
</template>

<script>
export default {
  name: 'Navigator365Logo',
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
img {
  &:not(.inline) {
    display: block;
  }

  &.inline {
    height: 2em;
    margin-bottom: -0.75rem;
  }
}
</style>

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import './styles/global.scss';

import VueMoment from 'vue-moment';
Vue.use(VueMoment);

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

import VueTippy, { TippyComponent } from 'vue-tippy';
Vue.use(VueTippy, {
  directive: 'tippy',
});
Vue.component('tippy', TippyComponent);

import InlineSvg from 'vue-inline-svg';
Vue.component('inline-svg', InlineSvg);

import './utilities/filters';

Vue.config.productionTip = false;

const domain = location.host;
const dotCount = domain.match(/\./g)?.length;
const domainAfterDot =
  dotCount == 2 ? domain.substring(domain.indexOf('.') + 1) : '';
const domainBeforeDot = dotCount == 2 ? domain.split('.')[0] : '';
const now = Date.now();
let newDomain = '';

// ⬇️ This code is used to redirect to navigator365.cloud domain after 25/09/2024
const redirectTimestamp = 1727287200000; // 25/09/2024 20:00
if (
  domainAfterDot !== 'navigator365.cloud' &&
  domain !== 'localhost:8083' &&
  now > redirectTimestamp
) {
  newDomain =
    (dotCount == 2 ? domainBeforeDot + '.' : '') + 'navigator365.cloud';
  location.host = newDomain;
}
// ⬆️ This code is used to redirect to navigator365.cloud domain after 25/09/2024

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

const products = {
  CORE: {
    value: 8,
    name: 'Core',
    displayName: 'Navigator365 Core',
  },
  PLANNER: {
    value: 2,
    name: 'Planner',
    displayName: 'Navigator365 Planner',
  },
  TRACKER: {
    value: 3,
    name: 'Tracker',
    displayName: 'Navigator365 Tracker',
  },
  MANAGEMENT_MODULES: {
    value: 5,
    name: 'Management tool',
    displayName: 'Atlas Management modules',
  },
  MISSING_MANUAL: {
    value: 6,
    name: 'Missing manual',
    displayName: 'Missing manual',
  },
  BENCHMARK: {
    value: 7,
    name: 'Benchmark',
    displayName: 'Navigator365 Benchmark',
  },
  // TODO phase out
  NAVIGATOR_CORE: {
    value: 1,
    name: 'Nav365',
    displayName: 'Navigator365 Core',
  },
};

export default products;

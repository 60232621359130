import Oidc from 'oidc-client';
import store from '@/store';
import {
  OIDC_CLIENT_ID,
  OIDC_DISABLE_MONITOR_SESSION,
  OIDC_LOGGING,
  OIDC_SCOPES,
  SSO_BASE_URL,
} from '@/utilities/constants';

const settings = {
  userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),

  authority: SSO_BASE_URL,
  client_id: OIDC_CLIENT_ID,
  redirect_uri: window.location.origin + '/auth/callback.html',
  post_logout_redirect_uri: window.location.origin,

  response_type: 'code',
  scope: OIDC_SCOPES,

  automaticSilentRenew: false, // this is handled below with the AccessTokenExpiring event
  silent_redirect_uri: window.location.origin + '/auth/silent-renew.html',

  // Should OIDC protocol claims be removed from profile. (eg sub)
  filterProtocolClaims: false,

  // when developing (running on localhost) with a non-local STS over https,
  // using the silent renew will cause SameSite cookie issues
  // and result in constant redirects in some browsers
  monitorSession: !OIDC_DISABLE_MONITOR_SESSION,

  // accessTokenExpiringNotificationTime: 20, // default 60
};
const userManager = new Oidc.UserManager(settings);

class AuthService {
  getUser() {
    return userManager.getUser();
  }

  async isLoggedIn(user = null) {
    if (user == null) {
      user = await this.getUser();
    }
    return user !== null && !user.expired;
  }

  login(returnPath = null) {
    const data = returnPath == null ? null : { state: returnPath };
    return userManager.signinRedirect(data);
  }

  logout() {
    return userManager.signoutRedirect();
  }

  silentLogout() {
    return userManager.removeUser();
  }
}

if (OIDC_LOGGING) {
  Oidc.Log.logger = console;
  Oidc.Log.level = Oidc.Log.INFO;
}

userManager.events.addAccessTokenExpiring(() => {
  userManager
    .signinSilent()
    .then((user) => store.dispatch('auth/setUserInfo', user));
});

userManager.events.addUserSignedOut(async () => {
  await userManager.removeUser();
  if (
    window &&
    window === window.top // not in iframe (signout page on sso)
  ) {
    await userManager.signinRedirect();
  }
});

export default new AuthService();

// https://www.richard-banks.org/2018/11/securing-vue-with-identityserver-part7.html
// https://damienbod.com/2019/01/29/securing-a-vue-js-app-using-openid-connect-code-flow-with-pkce-and-identityserver4/
// https://www.jerriepelser.com/blog/using-auth0-with-vue-oidc-client-js/
// https://www.jerriepelser.com/blog/secure-vue-routes-auth0-oidc-client
// https://www.scottbrady91.com/OpenID-Connect/Silent-Refresh-Refreshing-Access-Tokens-when-using-the-Implicit-Flow
// https://www.scottbrady91.com/Angular/Migrating-oidc-client-js-to-use-the-OpenID-Connect-Authorization-Code-Flow-and-PKCE

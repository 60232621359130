import SsoApiService from './base/sso-api.service';

class SubscriptionsService extends SsoApiService {
  async getSubscriptions(includeTherapeuticAreas = false) {
    let url = '/subscriptions';
    if (includeTherapeuticAreas) {
      url += '?includeTherapeuticAreas=true';
    }
    return (await this.get(url)).data;
  }
}

export default new SubscriptionsService();

<template>
  <div v-if="!isProduction" class="indicator">
    {{ displayName }}
  </div>
</template>

<script>
import { ENVIRONMENT, ENVIRONMENTS } from '@/utilities/constants';
import { stringIsNullOrWhitespace } from '@/utilities/string-helper';

export default {
  name: 'EnvironmentIndicator',
  computed: {
    isProduction() {
      return stringIsNullOrWhitespace(ENVIRONMENT);
    },
    displayName() {
      return ENVIRONMENTS[ENVIRONMENT];
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/variables';

.indicator {
  background-color: $red;
  text-align: center;
  color: $white;
  font-weight: 600;
}
</style>

import subscriptionsService from '@/services/sso-api/subscriptions.service';

export default {
  namespaced: true,
  state: {
    listLoading: false,
    listResult: null,
  },
  mutations: {
    putListLoading(state, value) {
      state.listLoading = value;
    },
    putListResult(state, value) {
      state.listResult = value;
    },
    putDetailLoading(state, value) {
      state.detailLoading = value;
    },
  },
  actions: {
    resetList({ commit }) {
      commit('putListLoading', false);
      commit('putListResult', null);
    },
    async fetchList({ commit, state }, force = false) {
      if (state.listResult != null && !force) return;

      commit('putListLoading', true);

      const result = await subscriptionsService.getSubscriptions();
      commit('putListResult', result);

      commit('putListLoading', false);
    },
  },
  getters: {},
};

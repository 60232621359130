<template>
  <router-view class="router" />
</template>

<script>
export default {
  name: 'UnauthenticatedTemplate',
};
</script>

<style scoped></style>

import Vue from 'vue';
import { VALIDATION_ERROR_MESSAGES, VALIDATION_ERROR_TYPES } from './constants';

Vue.filter('printValidationError', (errorObject) => {
  return `${errorObject.fieldInfo} ${
    VALIDATION_ERROR_TYPES[errorObject.validationErrorType]
  }${errorObject.messageKey?.length > 0 ? ` (${errorObject.messageKey})` : ''}`;
});

Vue.filter('printValidationErrorType', (type) => {
  return VALIDATION_ERROR_TYPES[type];
});

Vue.filter('printValidationErrorMessage', (message) => {
  return VALIDATION_ERROR_MESSAGES[message];
});

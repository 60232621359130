<template>
  <div class="brand">
    <router-link :to="{ name: routeInfo.home.name }" class="link-reset">
      <Navigator365Logo class="logo" />
    </router-link>
  </div>
</template>

<script>
import routeInfo from '@/router/route-info';
import Navigator365Logo from '@/components/common/Navigator365Logo';

export default {
  name: 'HeaderBrand',
  components: { Navigator365Logo },
  computed: {
    routeInfo: () => routeInfo,
  },
};
</script>

<style scoped lang="scss">
.brand {
  width: 180px;
  height: 100%;
  display: block;
}

.logo {
  display: block;
  height: 100%;
}
</style>

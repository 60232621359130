import invitationsService from '@/services/sso-api/invitations.service';

export default {
  namespaced: true,
  state: {
    statsLoading: false,
    statsResult: null,

    createLoading: false,
    createResult: null,
  },
  mutations: {
    putStatsLoading(state, value) {
      state.statsLoading = value;
    },
    putStatsResult(state, value) {
      state.statsResult = value;
    },
    putCreateLoading(state, value) {
      state.createLoading = value;
    },
    putCreateResult(state, value) {
      state.createResult = value;
    },
  },
  actions: {
    resetStats({ commit }) {
      commit('putStatsLoading', false);
      commit('putStatsResult', null);
    },
    resetCreate({ commit }) {
      commit('putCreateLoading', false);
      commit('putCreateResult', null);
    },
    async fetchStats({ commit, dispatch }) {
      dispatch('resetStats');
      commit('putStatsLoading', true);
      const result = await invitationsService.getStatistics();
      commit('putStatsResult', result);
      commit('putStatsLoading', false);
    },
    async inviteUsers({ commit, dispatch }, data) {
      dispatch('resetCreate');
      commit('putCreateLoading', true);

      try {
        const result = await invitationsService.inviteUsers(data);
        commit('putCreateResult', result);
      } catch (e) {
        const result = e.response?.data ?? { success: false };
        commit('putCreateResult', result);
      }

      commit('putCreateLoading', false);
    },
  },
  getters: {},
};

import scalaService from '@/services/sso-api/scala.service';

export default {
  namespaced: true,
  state: {
    gameLoading: false,
    gameResult: null,

    loginLinkLoading: false,
    loginLinkResult: null,
  },
  mutations: {
    putGameLoading(state, value) {
      state.gameLoading = value;
    },
    putGameResult(state, value) {
      state.gameResult = value;
    },
    putLoginLinkLoading(state, value) {
      state.loginLinkLoading = value;
    },
    putLoginLinkResult(state, value) {
      state.loginLinkResult = value;
    },
  },
  actions: {
    resetGame({ commit }) {
      commit('putGameLoading', false);
      commit('putGameResult', null);
    },
    resetLoginLink({ commit }) {
      commit('putLoginLinkLoading', false);
      commit('putLoginLinkResult', null);
    },
    async fetchGame({ commit, state, dispatch }, force = false) {
      if (state.gameResult != null && !force) return;
      dispatch('resetGame');

      commit('putGameLoading', true);

      try {
        const result = await scalaService.getGameInfo();
        commit('putGameResult', result);
      } catch (e) {
        console.error('No Scala Game info available for this user.');
      }

      commit('putGameLoading', false);
    },
    async fetchLoginLink({ commit, state }, force = false) {
      if (state.loginLinkResult != null && !force) return;
      commit('putLoginLinkLoading', true);

      let result = null;
      try {
        result = await scalaService.getGameLoginLink();
      } catch (e) {
        result = e?.response?.data;
      }
      commit('putLoginLinkResult', result);

      commit('putLoginLinkLoading', false);
    },
  },
  getters: {},
};

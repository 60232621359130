import SsoApiService from './base/sso-api.service';

class InvitationsService extends SsoApiService {
  async getStatistics() {
    return (await this.get('/invites/stats')).data;
  }

  async inviteUsers(data) {
    return (await this.post('/invites', data)).data;
  }
}

export default new InvitationsService();

<template>
  <div class="welcome">
    <div class="heading">
      <div class="heading-1">
        Welcome to <Navigator365Logo :inline="true" />
      </div>
    </div>
    <p class="welcome-text">
      The insights and the tools you need to plan, execute and measure an
      effective evidence-based omnichannel customer engagement strategy –
      whenever and wherever you need them.
    </p>
    <p class="welcome-text">
      Learn more about
      <a href="https://precisionaq.com" target="_blank">Precision AQ</a>.
    </p>
  </div>
</template>

<script>
import Navigator365Logo from '@/components/common/Navigator365Logo';

export default {
  name: 'HomeWelcome',
  components: { Navigator365Logo },
};
</script>

<style scoped lang="scss">
@import '~@/styles/variables';

.heading {
  &-1 {
    font-size: 1.4rem;
    font-weight: 300;
    margin-bottom: 8px;
  }

  &-2 {
    color: $orange;
  }

  &-2,
  &-3 {
    font-size: 1.6rem;
    font-weight: 600;
  }
}

a {
  color: $orange;
}
</style>

<template>
  <ModalBase @close="close">
    <template #header>
      <div class="header">
        <h2>Invite a colleague</h2>
        <div class="stats" v-if="stats">
          <tippy placement="left" arrow="true">
            <template #trigger>
              <div class="invited">
                <i class="fas fa-paper-plane"></i>
                {{ stats.invited }}
              </div>
            </template>

            Invitations sent
          </tippy>
          <tippy placement="left" arrow="true">
            <template #trigger>
              <div class="completed">
                <i class="fas fa-paper-plane"></i>
                {{ stats.completed }}
              </div>
            </template>

            Invitations accepted
          </tippy>
        </div>
      </div>
    </template>

    <template #body>
      <div class="text info-text">
        Fill out the form below to invite one or more
        <strong>{{ enterpriseName }}</strong> colleagues.
      </div>

      <div class="bulk" v-if="bulk">
        <div class="italic-text bulk-text">
          Enter each colleague you wish to invite on a new line. <br />
          Separate user fields with commas on each line. <br />
          You can also paste data directly from Excel.
        </div>
        <textarea
          class="bulk-input"
          v-model="bulkUsers"
          placeholder="First name, Last name, Email"
          @paste.prevent="handleBulkPaste"
        />
        <div class="errors">
          <div class="error" v-if="!canAddBulkRow">
            <i class="fas fa-exclamation-circle"></i>
            No more than {{ maxAllowed }} users can be invited at once.
          </div>
        </div>
        <div class="actions">
          <button type="button" class="btn-gray" @click="close">
            <i class="fas fa-times icon"></i>
            Cancel
          </button>
          <div class="right">
            <button
              type="button"
              class="btn-dark"
              @click="validateBulk"
              :disabled="formDisabled || !canAddBulkRow"
            >
              <i class="fas fa-check icon"></i>
              Validate
            </button>
          </div>
        </div>
      </div>
      <div class="form" v-else>
        <div class="form-header">
          <div class="first-name">First name</div>
          <div class="last-name">Last name</div>
          <div class="email">Email address</div>
          <div class="remove" v-if="users.length > 1"></div>
        </div>

        <div class="form-body">
          <div
            class="form-body-row"
            v-for="(user, i) in $v.users.$each.$iter"
            :key="i"
          >
            <div class="form-body-row-inputs">
              <div class="first-name">
                <input
                  type="text"
                  v-model.trim="user.firstName.$model"
                  :disabled="formDisabled"
                />
              </div>
              <div class="last-name">
                <input
                  type="text"
                  v-model.trim="user.lastName.$model"
                  :disabled="formDisabled"
                />
              </div>
              <div class="email">
                <input
                  type="email"
                  v-model.trim="user.email.$model"
                  :disabled="formDisabled"
                  :class="emailHasErrorInResult(user.email.$model)"
                />
              </div>
              <div class="remove" v-if="users.length > 1">
                <tippy placement="top" arrow="true">
                  <template #trigger>
                    <button
                      type="button"
                      class="btn-icon"
                      @click="removeRow(i)"
                    >
                      <i class="fas fa-minus-circle"></i>
                    </button>
                  </template>
                  Remove this row
                </tippy>
              </div>
            </div>
            <div class="form-body-row-errors" v-if="user.$anyError">
              <div class="errors" v-if="user.firstName.$dirty">
                <div class="error" v-if="!user.firstName.required">
                  <i class="fas fa-exclamation-circle"></i> First name is
                  required
                </div>
              </div>
              <div class="errors" v-if="user.lastName.$dirty">
                <div class="error" v-if="!user.lastName.required">
                  <i class="fas fa-exclamation-circle"></i> Last name is
                  required
                </div>
              </div>
              <div class="errors" v-if="user.email.$dirty">
                <div class="error" v-if="!user.email.required">
                  <i class="fas fa-exclamation-circle"></i> Email is required
                </div>
                <div class="error" v-else-if="!user.email.email">
                  <i class="fas fa-exclamation-circle"></i>
                  Email must be a valid email address
                </div>
                <div class="error" v-else-if="!user.email.invalidDomain">
                  <i class="fas fa-exclamation-circle"></i> Inviting users with
                  <span>@{{ user.email.$model.split('@')[1] }}</span>
                  addresses is not allowed.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="result" v-if="createResult">
          <div v-if="createResult.success" class="success">
            <i class="fas fa-check"></i> Invitations were sent successfully.
          </div>
          <div v-else class="error">
            <div v-if="errors">
              <div v-for="(error, i) in errors" :key="i" class="result-error">
                {{ error.email }}:
                <span v-if="error.message">{{
                  error.message | printValidationErrorMessage
                }}</span>
                <span
                  v-else-if="
                    error.field === 'Email' && error.validationErrorType === 1
                  "
                >
                  a user already exists under this email address, an invitation
                  cannot be sent
                </span>
                <span v-else>
                  {{ error.field }}
                  {{ error.validationErrorType | printValidationErrorType }}
                </span>
              </div>
            </div>
            <div v-else>
              Something went wrong, please refresh the page and try again.
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="actions">
            <button type="button" class="btn-gray" @click="close">
              <i class="fas fa-times icon"></i>
              Cancel
            </button>
            <div class="right">
              <button
                type="button"
                class="btn-blue"
                @click="setupBulk"
                :disabled="formDisabled"
              >
                <i class="fas fa-users icon"></i>
                Bulk entry
              </button>
              <button
                type="button"
                class="btn-blue"
                @click="addUser"
                :disabled="formDisabled || !canAddRow"
              >
                <i class="fas fa-plus icon"></i>
                Add row
              </button>
              <button
                type="button"
                class="btn-dark"
                @click="submit"
                :disabled="$v.$invalid || formDisabled"
              >
                <i class="fas fa-spinner fa-spin" v-if="createLoading"></i>
                <i class="fas fa-user-plus icon" v-else></i>
                Invite
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="text domains italic-text">
        Ensure you only invite colleagues with a valid corporate email.
        <br />
        The following domains are allowed:
        <strong>{{ visibleDomains.join(', ') }} </strong>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import ModalBase from '@/components/common/modals/ModalBase';
import { mapActions, mapGetters, mapState } from 'vuex';
import { email, minLength, required } from 'vuelidate/lib/validators';
import {
  NEWLINE_REGEX,
  stringIsNullOrWhitespace,
} from '@/utilities/string-helper';
import { HIDDEN_INVITE_DOMAINS } from '@/utilities/constants';

// must be a function to access `this` and its computed properties
const matchesDomains = function (value) {
  return this.domains.some((d) => value.endsWith(`@${d}`));
};
const maxAllowed = 50;

export default {
  name: 'InviteColleague',
  components: { ModalBase },
  computed: {
    ...mapState({
      enterprise: (state) => state.auth.enterprise,
      enterpriseLoading: (state) => state.auth.enterpriseLoading,
      businesses: (state) => state.auth.businesses,
      businessesLoading: (state) => state.auth.businessesLoading,
      createLoading: (state) => state.invitations.createLoading,
      createResult: (state) => state.invitations.createResult,
      statsResult: (state) => state.invitations.statsResult,
    }),
    stats() {
      return this.statsResult?.data;
    },
    ...mapGetters({
      enterpriseName: 'auth/enterpriseName',
    }),
    formDisabled() {
      return (
        this.enterpriseLoading ||
        this.businessesLoading ||
        this.createLoading ||
        this.createResult?.success === true
      );
    },
    domains() {
      return this.enterprise?.domains ?? [];
    },
    visibleDomains() {
      return [...this.domains].filter(
        (d) => !HIDDEN_INVITE_DOMAINS.includes(d),
      );
    },
    businessIds() {
      return this.businesses.filter((e) => e.inviteAllowed).map((e) => e.id);
    },
    errors() {
      return this.createResult?.data?.errors;
    },
    success() {
      return this.createResult?.success === true;
    },
    canAddRow() {
      return this.users.length < this.maxAllowed;
    },
    canAddBulkRow() {
      return this.bulkUsers.split(NEWLINE_REGEX).length <= this.maxAllowed;
    },
    maxAllowed: () => maxAllowed,
  },
  data() {
    return {
      users: [],
      bulk: false,
      bulkUsers: '',
    };
  },
  validations: {
    users: {
      required,
      minLength: minLength(1),
      $each: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        email: {
          required,
          email,
          invalidDomain: matchesDomains,
        },
      },
    },
  },
  methods: {
    ...mapActions({
      fetchEnterprise: 'auth/fetchEnterprise',
      fetchStats: 'invitations/fetchStats',
      inviteUsers: 'invitations/inviteUsers',
      resetCreate: 'invitations/resetCreate',
    }),
    close() {
      this.$emit('close');
    },
    addUser() {
      this.users.push({
        firstName: '',
        lastName: '',
        email: '',
      });
    },
    removeRow(index) {
      this.users.splice(index, 1);
    },
    emailHasErrorInResult(email) {
      return {
        'error-input': this.errors?.some((err) => err.email === email),
      };
    },
    setupBulk() {
      this.bulkUsers = this.users
        .filter(
          // skip empty rows
          (u) =>
            !stringIsNullOrWhitespace(u.firstName) ||
            !stringIsNullOrWhitespace(u.lastName) ||
            !stringIsNullOrWhitespace(u.email),
        )
        .map((u) => `${u.firstName}, ${u.lastName}, ${u.email}`)
        .join('\n');
      this.bulk = true;
    },
    handleBulkPaste(event) {
      if (event.clipboardData) {
        let content = (event.originalEvent || event).clipboardData.getData(
          'text/plain',
        );
        content = this.transformPastedContent(content);
        document.execCommand('insertText', false, content);
      } else if (window.clipboardData) {
        let content = window.clipboardData.getData('Text');
        content = this.transformPastedContent(content);
        document.selection.createRange().pasteHTML(content);
      }

      return false;
    },
    transformPastedContent(content) {
      // replace tabs from Excel with comma separators
      // replace Windows-style newlines (also on macOS) with web-compatible ones
      return content.replaceAll('\t', ', ').replaceAll(NEWLINE_REGEX, '\n');
    },
    validateBulk() {
      if (stringIsNullOrWhitespace(this.bulkUsers)) {
        this.users = [];
        this.addUser();
      } else {
        const lines = this.bulkUsers.trim().split(NEWLINE_REGEX);
        const users = [];
        lines.forEach((l) => {
          if (stringIsNullOrWhitespace(l)) return;
          const values = l.split(',');
          users.push({
            firstName: values[0]?.trim() ?? '',
            lastName: values[1]?.trim() ?? '',
            email: values[2]?.trim() ?? '',
          });
        });
        this.users = users;

        // trigger vuelidate validation
        this.$v.$touch();
      }

      this.bulk = false;
    },
    submit() {
      // trigger vuelidate validation
      this.$v.$touch();
      // if any validation errors remain, return without executing api call
      if (this.$v.$invalid) return;

      const request = {
        users: this.users,
        enterpriseId: this.enterprise.id,
        businessIds: this.businessIds,
      };
      this.inviteUsers(request);
    },
  },
  watch: {
    success() {
      if (this.success === true) this.fetchStats();
    },
  },
  destroyed() {
    this.resetCreate();
  },
  mounted() {
    this.addUser();
    this.fetchEnterprise();
    this.fetchStats();
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/variables';

::v-deep .modal-container {
  min-width: 600px;
  max-width: 700px;
  width: 80%;
}

::v-deep .modal-body {
  margin-bottom: auto;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  margin-right: 0.5rem;

  .stats {
    display: flex;
    flex-direction: row;

    cursor: default;

    .completed i {
      color: $green-dark;
    }

    > div {
      margin-right: 1rem;
    }
  }
}

.italic-text {
  font-size: 85%;
  color: $blue-gray;
  font-style: italic;
}

.domains {
  margin-top: 1rem;
}

.form {
  .form-header,
  .form-body-row-inputs {
    display: flex;
    flex-direction: row;

    padding-left: 0.5rem;

    .first-name,
    .last-name,
    .email {
      padding-right: 1rem;

      display: flex;
      align-items: center;

      input {
        display: block;
        width: 100%;
      }
    }

    .first-name,
    .last-name {
      width: 25%;
    }

    .email {
      width: 45%;
    }

    .remove {
      width: 5%;

      display: flex;
      align-items: center;
      //background-color: $red;
      //border: 1px solid $green;

      i {
        font-size: 85%;
      }
    }
  }

  .form-header {
    font-weight: 400;
    padding-bottom: 0.5rem;
  }

  .form-body-row {
    border-bottom: 1px solid #fafafa;
    &:nth-child(odd) {
      background-color: #fafafa;
    }

    &:nth-child(even) {
      background-color: $white;
    }
  }

  .form-body-row-inputs {
    height: 3rem;
  }

  .form-body-row-errors {
    padding-bottom: 0.5rem;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
  }
}

.result {
  margin-top: 1rem;
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 1rem;

  .right {
    :not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.error-input {
  background-color: $red-light;
}

.info-text {
  margin-bottom: 1rem;
}

.bulk {
  .bulk-text {
    margin-bottom: 1rem;
  }

  .bulk-input {
    box-sizing: border-box;
    width: 100%;
    min-height: 200px;
    font-family: monospace;
  }
}
</style>

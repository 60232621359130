import SsoApiService from './base/sso-api.service';

class ScalaService extends SsoApiService {
  async getGameInfo() {
    return (await this.get('/scala/game')).data;
  }

  async getGameLoginLink() {
    return (await this.get('/scala/gameLogin')).data;
  }
}

export default new ScalaService();

<template>
  <header>
    <div class="left">
      <HeaderBrand />
    </div>
    <div class="right">
      <HeaderInviteColleague />
      <HeaderUserInfo />
      <div>
        <button type="button" class="btn-link" @click="logout">Log out</button>
      </div>
    </div>
  </header>
</template>

<script>
import { mapActions } from 'vuex';
import HeaderBrand from './HeaderBrand';
import HeaderUserInfo from '@/components/header/HeaderUserInfo';
import HeaderInviteColleague from '@/components/header/HeaderInviteColleague';

export default {
  name: 'AppHeader',
  components: {
    HeaderInviteColleague,
    HeaderUserInfo,
    HeaderBrand,
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout',
    }),
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/variables';

header {
  background-color: $background-color;
  height: $header-height;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: $box-padding;
  padding-right: $box-padding;

  .right {
    display: flex;
    flex-direction: row;
    align-items: center;

    > :not(:last-child) {
      margin-right: 1rem;
    }
  }
}
</style>

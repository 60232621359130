<template>
  <div>
    <GoogleTagManager v-if="gtmEnabled" />
    <Intercom v-if="intercomEnabled" />
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex';
import { GTM_ENABLED, INTERCOM_ENABLED } from '@/utilities/constants';
import GoogleTagManager from './GoogleTagManager';
import Intercom from './Intercom';

export default {
  name: 'TrackingScripts',
  components: { Intercom, GoogleTagManager },
  computed: {
    ...mapGetters({
      userFullName: 'auth/userFullName',
      userId: 'auth/userId',
      userEmail: 'auth/userEmail',
      enterpriseName: 'auth/enterpriseName',
      intercomUserHash: 'auth/intercomUserHash',
    }),
    gtmEnabled: () => GTM_ENABLED,
    intercomEnabled: () => INTERCOM_ENABLED,
  },
  created() {
  },
};
</script>

<style scoped lang="scss">
div {
  display: none;
}
</style>

import SsoApiService from './base/sso-api.service';

class BusinessesService extends SsoApiService {
  async getBusinesses() {
    const response = await this.get('/businesses');
    return response.data;
  }

  async getBusinessIdsForInvite() {
    const response = await this.get('/businesses/idsforinvite');
    return response.data;
  }
}

export default new BusinessesService();

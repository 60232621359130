const routeInfo = {
  notFound: {
    path: '*',
  },
  unauthorized: {
    path: '/unauthorized',
    name: 'Unauthorized',
  },
  logout: {
    path: '/logout',
    name: 'Logout',
  },
  silentLogout: {
    path: '/silent-logout',
    name: 'SilentLogout',
  },
  home: {
    path: '/home',
    name: 'Home',
  },
  omnitopia: {
    path: '/omnitopia',
    name: 'Omnitopia',
  },
};

export default routeInfo;

import authService from '@/services/auth/auth.service';
import enterprisesService from '@/services/sso-api/enterprises.service';
import businessesService from '@/services/sso-api/businesses.service';

export default {
  namespaced: true,
  state: {
    user: null,

    enterprise: null,
    enterpriseLoading: false,

    businesses: null,
    businessesLoading: false,
  },
  mutations: {
    putUser(state, payload) {
      state.user = payload;
    },
    putEnterprise(state, payload) {
      state.enterprise = payload;
    },
    putEnterpriseLoading(state, payload) {
      state.enterpriseLoading = payload;
    },
    putBusinesses(state, payload) {
      state.businesses = payload;
    },
    putBusinessesLoading(state, payload) {
      state.businessesLoading = payload;
    },
  },
  actions: {
    setUserInfo({ commit }, userInfo) {
      commit('putUser', userInfo);
    },
    login() {
      authService.login();
    },
    logout() {
      authService.logout();
    },
    silentLogout() {
      authService.silentLogout();
    },
    resetEnterprise({ commit }) {
      commit('putEnterprise', null);
      commit('putEnterpriseLoading', false);
    },
    resetBusinesses({ commit }) {
      commit('putBusinesses', null);
      commit('putBusinessesLoading', false);
    },
    async fetchEnterprise({ dispatch, commit, state }) {
      dispatch('resetEnterprise');

      const enterpriseId = state.user?.profile.EnterpriseId;
      if (enterpriseId == null || state.enterprise?.id === enterpriseId) return;

      commit('putEnterpriseLoading', true);

      const response = await enterprisesService.getEnterpriseById(enterpriseId);
      if (response != null && response.success)
        commit('putEnterprise', response.data);

      commit('putEnterpriseLoading', false);
    },
    async fetchBusinesses({ dispatch, commit }) {
      dispatch('resetBusinesses');

      commit('putBusinessesLoading', true);

      const response = await businessesService.getBusinesses();
      if (response != null && response.success)
        commit('putBusinesses', response.data);

      commit('putBusinessesLoading', false);
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.user !== null && !state.user.expired;
    },
    userFullName(state) {
      return state.user?.profile.name;
    },
    userId(state) {
      return state.user?.profile.sub;
    },
    userEmail(state) {
      return state.user?.profile.email;
    },
    getAccessToken(state) {
      return state.user?.access_token;
    },
    hasManagementAccess(state) {
      const permissions = state.user?.profile.Permissions ?? [];
      return permissions.length > 0;
    },
    hasPermission: (state) => (permission) => {
      return state.user?.profile.Permissions?.includes(permission) === true;
    },
    enterpriseName(state) {
      return state.user?.profile.Enterprise;
    },
    intercomUserHash(state) {
      return state.user?.profile.IntercomUserHash;
    },
    canInvite(state) {
      return state.businesses?.some((b) => b.inviteAllowed);
    },
  },
};

import { stringIsNullOrWhitespace } from '@/utilities/string-helper';

export const ENVIRONMENT = process.env.VUE_APP_ENVIRONMENT;

export const SSO_BASE_URL = process.env.VUE_APP_SSO_BASE_URL;

export const OIDC_CLIENT_ID = process.env.VUE_APP_OIDC_CLIENT_ID;
export const OIDC_SCOPES = process.env.VUE_APP_OIDC_SCOPES;
export const OIDC_LOGGING = process.env.VUE_APP_OIDC_LOGGING === 'true';
export const OIDC_DISABLE_MONITOR_SESSION =
  process.env.VUE_APP_OIDC_DISABLE_MONITOR_SESSION === 'true';

export const NAV365_URL = process.env.VUE_APP_NAV365_URL;
export const PLANNER_URL = process.env.VUE_APP_PLANNER_URL;
export const TRACKER_URL = process.env.VUE_APP_TRACKER_URL;
export const MANAGEMENT_TOOL_URL = process.env.VUE_APP_MANAGEMENT_TOOL_URL;
export const MANAGEMENT_TOOL_URL2 = process.env.VUE_APP_MANAGEMENT_TOOL_URL2;
export const MISSING_MANUAL_URL = process.env.VUE_APP_MISSING_MANUAL_URL;
export const BENCHMARK_URL = process.env.VUE_APP_BENCHMARK_URL;

export const INCLUDE_TRACKING = process.env.VUE_APP_INCLUDE_TRACKING === 'true';
export const GTM_ID = process.env.VUE_APP_GTM_ID;
export const GTM_ENABLED = !stringIsNullOrWhitespace(GTM_ID);
export const INTERCOM_APP_ID = process.env.VUE_APP_INTERCOM_APP_ID;
export const INTERCOM_ENABLED = !stringIsNullOrWhitespace(INTERCOM_APP_ID);

export const DEFAULT_DOCUMENT_TITLE = 'Precision AQ';

export const ENVIRONMENTS = {
  dev: 'Development',
  staging: 'Staging',
  next: 'Next',
};

export const VALIDATION_ERROR_TYPES = {
  0: 'is required',
  1: 'is not unique',
  2: 'is invalid',
  3: 'is too long',
  4: 'is too short',
  5: 'resulted in an expected error',
};

export const VALIDATION_ERROR_MESSAGES = {
  ThereMustBeAtLeastOneAdministrator:
    'At least one Administrator must remain in the platform.',
  CouldNotDeleteEnterpriseBecauseItStillHasBusinesses:
    'This account could not be deleted because it still has dependent subaccounts',
  CouldNotDeleteEnterpriseBecauseItStillHasUsers:
    'This account could not be deleted because it still has dependent users',
  CouldNotDeleteBusinessBecauseItStillHasUsers:
    'This subaccount could not be deleted because it still has dependent users',
  CouldNotDeleteBusinessBecauseItStillHasSubscriptions:
    'This subaccount could not be deleted because it still has dependent subscriptions',
  InvalidSourceEnterprise: 'Source account is not valid',
  EnterprisesDontMatch: 'Source account does not match target',
  InvalidSourceBusiness: 'Invalid source subaccount',
  NoSourceGiven: 'No source account or subaccount given',
  AlreadyInvitedToOtherEnterprise: 'User is already invited to another account',
  AppearsMoreThanOnce: 'This email address appears more than once in the list',
};

export const HIDDEN_INVITE_DOMAINS = ['a-cross.com', 'precisionvh.com'];

export const PERMISSIONS = {
  PlatformManagement: 'platform-management',
  SubscriptionManagement: 'subscription-management',
  DatasetManagement: 'dataset-management',
  Reporting: 'reporting',
  OrderManagement: 'order-management',
  ProductionManagement: 'production-management',
  FinancialManagement: 'financial-management',
};

<template>
  <div class="logo-container">
    <img src="../../assets/logo/logo.svg" alt="Precision AQ" class="logo" />
  </div>
</template>

<script>
export default {
  name: 'AcrossLogoPVH',
};
</script>

<style scoped lang="scss">
@import '~@/styles/variables';

.logo-container {
  flex-grow: 1;

  display: flex;
  flex-direction: row;
  align-items: center;

  height: 38px;

  img {
    max-height: 100%;
    height: 30px;
    margin: 4px 0;
  }

  .vertical-ruler {
    margin: auto 10px;
    width: 1px;
    height: 100%;
    background-color: $text-color;
  }
}
</style>

import Vue from 'vue';
import VueRouter from 'vue-router';
import { DEFAULT_DOCUMENT_TITLE } from '@/utilities/constants';
import authService from '@/services/auth/auth.service';
import store from '@/store';
import routeInfo from '@/router/route-info';
import Home from '@/views/Home';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: { name: routeInfo.home.name },
  },
  {
    name: routeInfo.home.name,
    path: routeInfo.home.path,
    component: Home,
  },
  {
    name: routeInfo.unauthorized.name,
    path: routeInfo.unauthorized.path,
    component: () => import('@/views/Unauthorized'),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: routeInfo.logout.path,
    name: routeInfo.logout.name,
    component: () => import('@/views/Logout'),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: routeInfo.silentLogout.path,
    name: routeInfo.silentLogout.name,
    component: () => import('@/views/SilentLogout'),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: routeInfo.omnitopia.path,
    name: routeInfo.omnitopia.name,
    component: () => import('@/views/Omnitopia'),
  },
  {
    path: routeInfo.notFound.path,
    redirect: { name: routeInfo.home.name },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // set title
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else {
    document.title = DEFAULT_DOCUMENT_TITLE;
  }

  if (to.matched.every((record) => record.meta.allowAnonymous)) {
    // no auth required, we can navigate
    next();
  } else {
    // authentication is required

    // get user data if available
    const user = await authService.getUser();
    // push retrieved data to store so it can be used in the auth module
    store.dispatch('auth/setUserInfo', user);
    const isAuthenticated = await authService.isLoggedIn(user);

    if (isAuthenticated) {
      if (
        to.matched.some(
          (r) =>
            r.meta.requirePermission != null &&
            !store.getters['auth/hasPermission'](r.meta.requirePermission),
        )
      ) {
        next(routeInfo.notFound.path);
      }

      // all good, allow user to go along
      next();
    } else {
      // Trigger the sign in process and pass the request url, so the user can
      // be redirected to back correctly after sign-in
      authService.login(to.fullPath);
    }
  }
});

export default router;

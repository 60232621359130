<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modal-container" role="dialog">
        <div class="modal-inner">
          <header class="modal-header">
            <slot name="header"></slot>
            <button
              v-if="showClose"
              type="button"
              class="btn-icon"
              @click="close"
            >
              <i class="fas fa-times"></i>
            </button>
          </header>

          <section class="modal-body">
            <slot name="body"></slot>
          </section>

          <footer class="modal-footer">
            <slot name="footer"></slot>
          </footer>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ModalBase',
  props: {
    showClose: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/styles/variables';
@import '~@/styles/mixins';

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.3);

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 2;
}

.modal-container {
  background-color: $white;

  border-radius: $border-radius;

  min-width: 250px;
  max-width: 500px;
  width: 80%;

  @include default-box-shadow;

  overflow: auto;
  max-height: 95%;

  display: flex;
  flex-direction: column;
}

.modal-inner {
  padding: $box-padding;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e6eaed;
  margin-right: 0px !important;

  .btn-close {
    width: auto;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.modal-body {
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

//.modal-fade-enter,
//.modal-fade-leave-to {
//  opacity: 0;
//}
//
//.modal-fade-enter-active,
//.modal-fade-leave-active {
//  transition: opacity 0.1s ease;
//}
</style>
